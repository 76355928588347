<template>
  <div class="grid grid-cols-md-2 gap-10 pt-1 pb-5">
    <div class="">
      <checkout-product v-for="product in products.subscriptions"
                        :key="product.key"
                        :product="product"
                        :disabled="isDisabled(product.key)"
                        @addNewProduct="addToCart"> </checkout-product>
    </div>
    <subscription-purchase :create-new="true" />
    <checkout-sidebar @addToCartClicked="addToCart" />
  </div>
</template>

<script>
import CheckoutProduct from "./CheckoutProduct";
import {mapState} from "vuex";
import SubscriptionPurchase from "../single/SubscriptionPurchase.vue";
import CheckoutSidebar from "@/components/structure/checkout/CheckoutSidebar.vue";
export default{
  components: {CheckoutSidebar, SubscriptionPurchase, CheckoutProduct},

  computed: {
    ...mapState({
      products: state => state.checkout.products,
      subscriptions: state => state.account.subscriptions,
      cartItems: state => state.checkout.subscription_cart_items,
    }),
  },
  watch: {
    cartItems(val) {
      if (!this.$store.state.checkout.serverSynced) return;
      this.axios.post('/cart', {
        type: 0,
        items: val.map(x => ({key: x.key, quantity: x.quantity, price: x.price, name: x.name }))
      }).catch(() => {})
    }
  },

  methods:{
    isDisabled(key){
      return this.cartItems.some(x => x.key === key) || this.subscriptions.some(sub => sub.products?.some(x => x.key === key))
    },
    addToCart(prod){
      this.$store.commit('checkout/addSubscriptionCartItem', prod);
    }
  }
}
</script>
<template>

  <div id="analysis">

    <b-tabs v-if="loaded" :value="mainTab" @input="setTab"  class="tabs-primary-filled pt-lg-0" lazy>
      <b-tab>
        <template #title>
          <TabTitle :title="$t('charts.analysis.foundation.title')" class="px-4" icon="icon-foundational-analysis"/>
        </template>
        <foundational />
      </b-tab>
      <b-tab>
        <template #title>
          <TabTitle :title="$t('charts.analysis.advanced.title')" class="px-3" icon="icon-advanced-analysis"/>
        </template>
        <advanced/>
      </b-tab>
      <b-tab>
        <template #title>
          <TabTitle :title="$t('charts.analysis.work_success.title')" class="px-4" icon="icon-v2-work-success"/>
        </template>

        <work-success />
      </b-tab>
    </b-tabs>
  </div>

</template>

<script>

import foundational from "./foundational/foundational";
import advanced from "./advanced/advanced";
import workSuccess from "./work_success/work_success.vue"
import TabTitle from "../../elements/TabTitle.vue";
import util from "../../../mixins/util";
import {mapState} from "vuex";

export default {

  components: {TabTitle, foundational, advanced, workSuccess},

  mixins: [util],

  computed:{
    ...mapState({
      mainTab: state => state.ui.analysis.mainTab
    })
  },
  watch:{
    mainTab(val){
      let urlQuery = this.getQuery();
      if(val !== undefined) urlQuery.set('analysis', this.findName(val));
      else urlQuery.delete('analysis');
      this.fixUrlQuery('?' + urlQuery.toString());
    }
  },

  created() {
    if (this.getQuery().has('analysis')){
      this.setTab(this.tabs.find(x => x.url === this.getQuery().get('analysis'))?.hash || 0);
    }
    this.loaded = true;
  },

  methods: {
    findName(tabId) {
      return this.tabs.find(x => x.hash === tabId).url
    },
    setTab(id) {
      this.$store.commit('ui/SET_ANALYSIS_TAB', {
        mainTab: id,
      })
    }
  },
  data() {
    return {
      loaded: false,
      tabs: [
        {
          name: this.$t('charts.analysis.foundation.title'),
          hash: 0,
          url: 'foundational'
        },
        {
          name: this.$t('charts.analysis.advanced.title'),
          hash: 1,
          url: 'advanced'
        },
        {
          name: this.$t('charts.analysis.work_success.title'),
          hash: 2,
          url: 'personal_success'
        }
      ]
    }
  },


}
</script>

export const localStorageKeys = {
    LIBRARY_PER_PAGE: 'LIBRARY_PER_PAGE',
    LIBRARY_SORT: 'LIBRARY_SORT',
    LOGGED_IN: 'AUTH.LOGGED_IN',
    CHART_ID: 'AUTH.CHART_ID',
    USER: 'AUTH.USER',
    TOKEN: 'AUTH.TOKEN',
    ACTIVATE_ACCOUNT: 'AUTH.ACTIVATE_ACCOUNT',
    IMPERSONATION: 'AUTH.IMPERSONATION',
    DARK_MODE: 'UI.DARK_MODE',
    ICHING_SCROLL_POSITION: 'UI.ICHING_SCROLL_POSITION',
    CHART_MODE: 'UI.CHART_MODE',
    CHART_VIEW_MODE: 'UI.CHART_VIEW_MODE',
    LAST_NOTIFICATION_READ: 'UI.LAST_NOTIFICATION_READ',
    GIFT: 'UI.GIFT',
    TRIPLE_ACTIVATION: 'UI.TRIPLE_ACTIVATION',

    VERSION:'VERSION',
}

export const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
}
export const warnIfUnknownKey = (key) => {
    if(localStorageKeys[key] || Object.values(localStorageKeys).includes(key)) return;
    console.warn("Please use only known localStorage keys for consistency. Key used:" + key);
}
export const setLocalStorageItem = (key, value, isObject = false) => {
    warnIfUnknownKey(key);
    if(!value) return localStorage.removeItem(key);
    if(isObject) return localStorage.setItem(key, JSON.stringify(value));
    return localStorage.setItem(key, value);
}

export const getLocalStorageItem = (key) => {
    warnIfUnknownKey(key);
    try{
        return JSON.parse(localStorage.getItem(key));

    }catch(e){
        return localStorage.getItem(key);
    }
}

export const SidebarVariants = {
    ShareChart: 'ShareChart',
    GiftChart: 'GiftChart',
    EditChart: 'EditChart',
    ImportCharts: 'ImportCharts',
    DownloadChartImage: 'DownloadChartImage',
    TransitSidebar: 'TransitSidebar',
    CheckoutSidebar: 'CheckoutSidebar',
    BulkTagSidebar: 'BulkTagSidebar',
    TagManager: 'TagManager',
}
<template>
  <side-extended-panel id="edit-chart"
                       :title="$t('billing.products.title')"
                       :close-btn-text="$t('owl.close')"
                       :visible="show"
                       @close="close">
    <div class="pb-5 pt-5">
      <div class="mb-2">
        <b-button v-for="variant in variants"
                  :key="variant"
                  :variant="activeTabType === variant ? 'primary' : 'link'"
                  @click="activeTabType = variant">
          {{ $t('billing.checkout.types.' + variant) }}
        </b-button>

      </div>

      <b-tabs :key="activeTabType" v-model="activeTabProduct"
              class="tabs-primary-underlined">
        <b-tab v-for="item in keys(activeTabType)"
               :key="item" :title="$t('billing.products.names.'+item)">

          <MbgFancyboxCarousel
              v-if="typeof getByKey(`checkout.${activeTabType}.${item}.carousel`, true) === 'object'"
              :images="getByKey(`checkout.${activeTabType}.${item}.carousel`)"
          />

          <div v-html="getByKey(`checkout.${activeTabType}.${item}.long`)"/>

          <div v-if="activeProduct" class="">
            <p v-if="activeProduct" class="ml-auto mb-0 font-weight-light" style="font-size:16px;">${{activeProduct.price.toFixed(2)}} </p>


            <b-button variant="primary" class="mt-2"
                      :disabled="isSubscriptionDisabled" @click="addToOrder">
              {{ $t('billing.checkout.addToOrder') }}
            </b-button>

          </div>


        </b-tab>
      </b-tabs>

    </div>
  </side-extended-panel>
</template>
<style>
.tabs-primary-underlined .nav-tabs{
  padding-left:0;
}
</style>

<script>
import SideExtendedPanel from "../SideExtendedPanel.vue";
import {mapState} from "vuex";
import ServerContentMixin from "@/mixins/ServerContentMixin";
import MbgFancyboxCarousel from "@/components/elements/MbgFancyboxCarousel.vue";
export default {
  components: {MbgFancyboxCarousel, SideExtendedPanel },
  mixins: [ServerContentMixin],
  computed: {
    ...mapState({
      products: state => state.checkout.products,
      show: state => state.ui.showCheckoutSidebar,
      subscriptions: state => state.account.subscriptions,
      cartItems: state => state.checkout.subscription_cart_items,
    }),

    activeTabType: {
      get() {
        return this.$store.state.ui.checkoutSidebarTab.type;
      },
      set(val) {
        this.$store.commit('ui/SET_CHECKOUT_SIDEBAR_ACTIVE_TAB', {
          type: val
        });
      }
    },

    activeTabProduct: {
      get() {
        const options = this.keys(this.variants[this.activeTabType]);
        return options.indexOf(this.$store.state.ui.checkoutSidebarTab.product);
      },
      set(val) {
        const options = this.keys(this.variants[this.activeTabType]);
        this.$store.commit('ui/SET_CHECKOUT_SIDEBAR_ACTIVE_TAB', {
          product: options[val]
        });
      }
    },

    activeProduct() {
      const tabType = this.activeTabType === 'single' ? 'singles' : 'subscriptions';
      return this.products[tabType]?.find(x => x.key === this.$store.state.ui.checkoutSidebarTab.product);
    },

    isSubscriptionDisabled(){
      if (this.activeTabType === 'single') return false;
      const prodKey = this.$store.state.ui.checkoutSidebarTab.product;
      return this.cartItems.some(x => x.key === prodKey) || this.subscriptions.some(sub => sub.products?.some(x => x.key === prodKey))
    },
  },
  data(){
    return {
      variants: ['single', 'subscription'],
      tabs: ['foundational', 'advanced', 'work_success', 'companionship', 'transit_tool'],
    }
  },
  methods: {
    keys(variant) {
      if (variant === 'single') {
        return this.tabs.filter(x => x !== 'transit_tool');
      }
      return this.tabs;
    },
    close(){
      this.$store.commit('ui/SET_SHOW_CHECKOUT_SIDEBAR', false);
    },
    addToOrder() {
      this.$emit('addToCartClicked', this.activeProduct);
      this.close();
    }
  }

}
</script>
<template>
  <div class="px-0" :class="showTitle ? 'mt-2' : 'mt-4'">
    <div v-if="showTitle" class="mb-4">
      <h3 v-if="!hideGateNum" class="font-weight-bold">{{ $t('hd.gates.name.one') }} {{gateId}}</h3>
      <h5 class="font-weight-normal">{{$t('hd.gates.'+gateId + '.name') }} - {{$t('hd.gates.'+gateId + '.titleKeynote') }}</h5>
    </div>
    <div v-if="content && !hideContent">
      <h1 class="keynote-title mb-4">
        {{showPersonal ? content.keynote_personal : content.keynote }}
      </h1>
      <audio-player v-if="!loading && content.audio" :key="gateId" :file-url="content.audio" />

      <mbg-accordion-item v-if="content.description" class="mt-4"
                          :text="showPersonal ? $t('charts.properties.analysis') : $t('charts.properties.overview')"
                          :reverse-background="reverseBackground"
                          :content="showPersonal ? content.description_personal : content.description" />
    </div>
  </div>
</template>

<script>
import AudioPlayer from "../../../structure/AudioPlayer";
import {isCompositeDocumentType} from "../../../../helpers/docTypes";
import MbgAccordionItem from "../../../elements/MbgAccordionItem.vue";
export default{

  props:{
    gateId: Number,
    loading: Boolean,
    showTitle:{
      type: Boolean,
      default: false
    },
    hideContent: {
      type: Boolean,
      default: false,
    },
    hideGateNum: {
      type: Boolean,
      default: false
    },
    reverseBackground: Boolean,
    isRelatedToggle: Boolean,
  },
  components:{MbgAccordionItem, AudioPlayer},
  data(){
    return{
      content: null,
    }
  },
  computed:{
    showPersonal(){
      return !isCompositeDocumentType(this.$store.state.charts.chart.meta.type);
    }
  },
  watch:{
    gateId(val){
      if(val) this.getContent();
    }
  },

  created(){
    this.getContent();
  },
  methods:{
    getContent(){
      this.$emit('loading', true)
      this.axios.get(`/content/${this.$route.params.chartId}/gates/${this.gateId}`).then(res=>{
        this.content = res.data;
      }).catch(e=>{
        console.log(e);
      }).finally(()=>{
        this.$emit('loading', false)
      })
    },
  }

}
</script>
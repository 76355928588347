<template>

  <b-overlay rounded="sm" :show="showLoader">

    <b-card v-show="createNew" class="p-3">

      <global-coupon-display type="subscriptions" :invalid-coupon="invalidCoupon" @applyCoupon="(val) => {
        coupon = val;
        applyCoupon();
      }"/>

      <div v-if="showLoader && step !== 1 && !isApplying " class="d-flex align-items-center justify-content-center"
           style="min-height:300px;">
        {{ $t('billing.processing') }}
      </div>
      <div v-else-if="createNew && $route.query.action !== 'execute-payment'">

       <!--   Step 0. Order summary.    -->
        <div v-show="step === 0">

          <div v-if="cart_items.length === 0">
            <div class="alert-primary-side" v-html="$t('billing.checkout.subscription.text')"/>
            <empty-cart class="mt-5" :subtitle="$t('billing.checkout.subscription.empty')"/>
          </div>
          <div v-else>

            <h6 class="mb-3"> {{$t('billing.checkout.products')}}</h6>

            <cart-item v-for="item in cart_items" :key="item.id" :item="item" @removeFromCart="removeFromCart"></cart-item>

          </div>

          <div v-if="cart_items.length > 0" class="mt-5">
            <div>
              <div class="d-flex flex-wrap align-items-center my-4">
                <mbg-text-input v-model="coupon" placeholder="Your coupon..."
                                :disabled="!!couponObject"
                                :validation="invalidCoupon ? {
                                  $error: $t('billing.checkout.expiredCoupon')
                                }: undefined"
                                @input="x => coupon = x.toUpperCase()"
                                input-classes="mt-0 mb-0" class="my-0 flex-shrink-0 w-75 pr-3"/>
                <b-button v-if="!couponObject" size="" variant="primary" class=" w-25" @click="applyCoupon" :disabled="isApplying || !coupon || !!couponObject">
                  {{$t('billing.apply')}}
                </b-button>
                <b-button v-else variant="link" @click="couponObject = null">
                  {{$t('billing.remove')}}
                </b-button>
                <p v-if="invalidCoupon" class="text-danger">{{$t('billing.checkout.expiredCoupon')}}</p>
                <b-alert :show="isAutoCoupon || (cart_items.length === 1 && !couponObject)" :variant="isAutoCoupon ? 'success' : 'warning'" class="mt-3 w-100 d-flex align-items-center gap-10">
                  <span :class="isAutoCoupon ? 'icon-check' : 'icon-info-outline'" />
                  <span v-if="isAutoCoupon">
                      {{$t('billing.checkout.autoCoupon')}}
                  </span>
                  <span v-else>
                    {{$t('billing.checkout.addMoreForCoupon')}}
                  </span>

                </b-alert>

              </div>
            </div>

            <h6 class="mb-3">{{$t('billing.checkout.summary')}}</h6>

            <summary-item v-for="variation in cartVariations" :key="variation.key"
                          :name="$t('billing.unlockTypes.'+variation.key + `${variation.key === 'transit_tool' ? '_checkout': ''}`)"
                          :value="variation.quantity * variation.price"
                          :discount="variation.discount"
                          :quantity="variation.quantity"
                          :discounted-price="variation.discountedPrice"
            />
            <summary-item v-if="cartDiscounts > 0"  class="mt-5" :name="$t('billing.checkout.subtotal')" :value="cartSubTotal"/>
            <summary-item v-if="cartDiscounts > 0" class="mt-2" :name="$t('billing.checkout.discounts')" :value="cartDiscounts"/>
            <summary-item class="mt-3 font-500" :name="$t('billing.checkout.total')" :value="cartTotal"/>

            <b-button variant="primary" class="btn-block mt-4" @click="completePayment" :disabled="!canInitiatePurchase">{{$t('billing.checkout.subscribe')}}</b-button>
          </div>
       </div>
      </div>

        <finalize-stripe-payment
            v-if="step === 1"
            :process-payment="createSubscription"
            :cart-total="cartTotal"
            @purchasing="val => this.isPurchasing = val"
            @handlePurchase="afterPurchaseActions"/>
    </b-card>
  </b-overlay>

</template>

<style lang="scss">
.pay-method .custom-radios .custom-control-label{
  padding-top:6px!important;
}
</style>
<script>
import {mapState} from "vuex";
import CartItem from "../cart/CartItem";
import SummaryItem from "../cart/SummaryItem";
import EmptyCart from "./EmptyCart.vue";
import FinalizeStripePayment from "../providers/FinalizeStripePayment.vue";
import MbgTextInput from "../../elements/MbgTextInput.vue";
import GlobalCouponDisplay from "../cart/GlobalCouponDisplay.vue";
import DiscountMixin from "../../../mixins/DiscountMixin";


export default{

  props:{
    createNew: Boolean
  },
  mixins: [DiscountMixin],
  components: {GlobalCouponDisplay, MbgTextInput, FinalizeStripePayment, EmptyCart, SummaryItem, CartItem},
  watch: {
    cart_items() {
      this.refreshAndAutoApplyCoupon();
    }
  },
  computed:{
    ...mapState({
      cart_items: state => state.checkout.subscription_cart_items
    }),

    autoCoupons() {
      return ['COMBO_SMALL', 'COMBO_MEDIUM', 'COMBO_LARGE'];
    },

    isAutoCoupon() {
      return this.autoCoupons.includes(this.coupon) && !!this.couponObject;
    },
    cartVariations(){
      let variations = {};

      for(let item of this.cart_items){
        if(!variations[item.key]){
          variations[item.key] = {
            name: item.name,
            key: item.key,
            quantity: 1,
            price: item.price,
            ...this.getDiscountForProduct(item)
          }
        }
      }
      return Object.values(variations);
    },

    showLoader(){
      return this.isPurchasing || this.$route.query.action === 'execute-payment' || this.isApplying
    },
    canInitiatePurchase(){
      return this.cart_items.length > 0
    },
  },
  mounted(){
    this.step = 0;
    this.refreshAndAutoApplyCoupon();
  },


  data(){
    return{
      selectedChart: null,
      addNew: false,
      step: 0,
      payment_provider: 1,
      isApplying: false,
      isPurchasing: false,
      stripe_data: {},
      stripe: null,
      validCoupon: false,
      couponObject: null,
      coupon: null,
      stripe_elements: null,
      paymentElement: null,
      invalidCoupon: false,
      showWelcomeCouponNotice: false,
    }
  },
  methods:{
    refreshAndAutoApplyCoupon() {
      this.couponObject = null;
      // const totalSubscriptions = this.cart_items.filter(x => x.key !== 'transit_tool').length;
      if (this.coupon &&
          !this.autoCoupons.includes(this.coupon)) return this.applyCoupon();

      if (this.cartSubTotal >= 598 && this.cartSubTotal <= 798) {
        this.coupon = 'COMBO_SMALL';
      } else if (this.cartSubTotal >= 1097 && this.cartSubTotal <= 1597) {
        this.coupon = 'COMBO_MEDIUM';
      } else if (this.cartSubTotal >= 1896) {
        this.coupon = 'COMBO_LARGE';
      } else {
        this.coupon = null;
      }

      this.applyCoupon();
    },
    applyCoupon(){
      if (!this.coupon) return;
      this.invalidCoupon = false;
      this.isApplying = true;
      this.couponObject = null;
      this.coupon = this.coupon.trim();
      this.axios.get(`billing/subscriptions/coupon?coupon=${this.coupon}&amount=${this.cartSubTotal}`).then(res =>{
        if(!res.data.error) this.couponObject = res.data;
        else this.invalidCoupon = true;
      }).catch( () =>{
        this.invalidCoupon = true;
      }).finally(()=>{
        this.isApplying = false;
      });
    },

    removeFromCart(item){
      this.$store.commit('checkout/removeSubscriptionFromCart', item);
    },

    createSubscription(paymentMethod = undefined) {
      return this.axios.post('/billing/create-subscription', {
        payment_provider: this.payment_provider,
        paymentMethod,
        cart: this.cart_items.map((x) => {
              return { product_key: x.key }
            }
        ),
        coupon: this.couponObject?.coupon?.id
      })
    },

    executeSubscriptionCall() {
      this.isPurchasing = true;
      this.createSubscription().then(res=>{
        // handle paypal response
        if(this.payment_provider === 0) window.location.href = res.data;

        if(this.payment_provider === 1){
          this.isPurchasing = false;
          this.step = 1;
          this.stripe_data = res.data;
          this.setupStripe();
        }
      }).catch(()=>{
        this.$notify({type: 'error', title: this.$t('errors.general'), text: this.$t('errors.retry')});
        this.isPurchasing = false;
      })
    },

    completePayment(){
      if(this.isPurchasing) return;

      if(this.payment_provider === 0) return this.executeSubscriptionCall();
      else this.step = 1;
    },

    afterPurchaseActions(){
      setTimeout(()=>{
        // mark checkout completed as true (to show the checkout complete screen)
        this.$store.commit('checkout/SET_CHECKOUT_COMPLETED', true);
        this.$store.commit('checkout/SET_COMPLETED_CHECKOUT_KIND', 'subscription');
        this.$store.commit('ui/SET_LAST_TRANSACTION_VALUE', this.cartTotal);
        this.$store.commit('checkout/clearSubscriptionCart');
        this.$store.dispatch('account/getUserSubscriptions'); // re-fetch user unlocked charts!
      }, 5000);
    }
  }
}
</script>
<template>
  <span v-if="productKey === 'foundational'" class="icon-foundational-analysis"/>
  <span v-else-if="productKey === 'advanced'" class="icon-analysis-advanced"/>
  <span v-else-if="productKey === 'work_success'" class="icon-v2-work-success"/>
  <span v-else-if="productKey === 'companionship'" class="icon-companionship"/>
  <span v-else-if="productKey === 'transit_tool'" class="icon-transit"/>
</template>
<script>
export default{

  props: {
    productKey: String
  }
}

</script>
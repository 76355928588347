import {mapState} from "vuex";

export default {
    computed: {
        ...mapState({
            clientContent: state => state.ui.clientContent,
        }),
    },

    methods: {
        getByKey(key, safeObjectAccess = false) {
            if (!this.clientContent) return safeObjectAccess ? null : key;
            return this.clientContent[key] || key
        },
    }
}
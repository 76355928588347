
export default{
    apply: 'Apply',
    remove: 'Remove',
    unlimited: 'No expiration',

    processing: 'Transaction processing...',
    orderId: 'Order No.',
    noSubscriptions: 'You do not have any active or past subscriptions.',
    noPurchases: 'You do not have any current or past purchases.',
    noCards: 'You do not have any saved cards.',
    delete: 'Delete card',
    deleteCardTitle: 'Are you sure you want to delete this card?',
    deleteCardText: 'If you delete this card, you will have to re-authorize it for usage in your subscriptions or purchases.',
    changeCard: 'Change card',
    addCard: 'Add new card',
    replace:'Your current card will be replaced with this card.',
    save: 'Save your card and pay faster!',
    saveCard: 'Save Card',
    summary: 'Order Summary',
    providers:{
        0: 'Paypal',
        1: 'Credit / Debit Card',
        2: 'Free'
    },
    payMethod: 'Payment Provider',
    statuses:{
        2: 'Completed'
    },
    subStatuses: {
        1: 'Active',
        2: 'Deleted',
        willCancel: 'Cancels on period end'
    },
    status: 'Status',
    startedOn: 'Start Date',
    activeUntil: 'Active until',
    skus: {
        foundational_rave_chart: 'myBodyGraph - Single Chart Foundational Analysis',
        advanced_rave_chart: 'myBodyGraph - Single Chart Advanced Analysis',
        work_success_chart: 'myBodyGraph - Single Chart Personal Success Analysis',
        full_rave_chart_combo: 'myBodyGraph - Full Single Chart Analysis',
        connection_chart: 'myBodyGraph - Full Connection Chart Analysis',
    },
    tabs:{
        subscriptions:{
            title: 'Subscriptions'
        },
        purchases:{
            title: 'Purchases'
        },
        cards: {
            title: 'Payment Method'
        },
        coupons: {
            title: 'Coupons',
            code: 'Coupon Code',
            empty: 'No coupons available.',
            claimed: 'Already claimed.',
            single: 'Applies to Single Chart Unlocks only',
            subscription: 'Applies to Subscriptions only',
            expiration:'Expiration date',
            duration: {
                once: 'First year discount',
                forever: 'Lifetime discount'
            },
            appliesTo: 'Valid for:'
        },
        gifts: {
            title: 'Gifts'
        },
        invoiceOptions: {
            optional: 'All fields are optional, and saved changes will apply to future and old invoices. Your name can be edited under Settings > Account.',
            company: 'Company',
            title: 'Invoice Options',
            address: 'Address',
            vat: 'VAT number',
            city: 'City',
            country: 'Country',
            zip_code: 'Postal Code',
            state: 'State/Region'
        }
    },
    unlockTypes: {
        foundational: "Foundational Analysis",
        advanced: "Advanced Analysis",
        companionship: "Partnership Analysis",
        transit_tool: "All Access",
        transit_tool_checkout: 'The Transit Tool',
        celebrity: 'Analysis Access',
        work_success: 'Personal Success Analysis'
    },
    chart:{
        single:{
            title: 'Single Unlock',
            description: 'Unlock {plan} for this chart with full analysis forever',
            foundational: {
                bullets: 'Unlock a detailed analysis of your unique Human Design. This in-depth analysis reveals how to make aligned decisions, uncover your strengths, and live with greater clarity and ease. Perfect for personal exploration of your design with practical insights to transform your life.'
            },
            advanced: {
                bullets: 'Unlock a detailed analysis of your potential transformation through the Four Transformations (Variable). Discover your ideal environment, dietary regimen, perspective, and motivation for a radically aligned life. Perfect for a personal deep dive.',
            },
            work_success: {
                bullets: 'Access a detailed analysis of your unique skills and attributes in work environments, especially small groups. Optimize your performance and financial success by understanding your strengths. Perfect for anyone looking to boost their potential.',
            },
            companionship: {
                bullets: 'Gain a detailed analysis of your connection chart. Understand how your energies interact, navigate challenges with clarity, and bring greater harmony to your connection. Perfect for understanding any type of relationship.',
            },

           },
        subscription:{
            title: 'Unlimited Charts',
            oneTimePayment: 'One-time Payment',
            paidYearly: 'Paid Yearly',
            description: 'Unlock {plan} for unlimited charts for a year',
            savings: 'Save ${amount} if you unlock {count} charts',
            needMore: 'Need More?',
            savingsCalculator: 'Use our savings calculator',
            foundational: {
                bullets: 'Unlock unlimited in-depth analyses for yourself, family, friends, or clients with our yearly subscription. Dive deeper into multiple charts, offering continual insights into Human Design for anyone in your life. Ideal for those who want to explore Human Design for personal use or professional guidance.'
            },
            advanced: {
                bullets: 'Gain access to unlimited advanced analyses for yourself, family, friends, or clients with our yearly subscription. Explore the unique transformations of each person in your circle, providing insights for both personal and professional development. Ideal for those seeking continual transformation across multiple charts.',
            },
            work_success: {
                bullets: 'Unlock unlimited Personal Success analyses for your team or clients with our yearly subscription. Gain continual insights into how multiple people perform in small group settings, offering valuable guidance for managers, business owners, or BG5 Certified Consultants working with teams.',
            },
            companionship: {
                bullets: 'Unlock unlimited relationship analyses to explore the dynamics between yourself and limitless others. Whether for personal use or for guiding clients, this subscription provides ongoing insights into the energy interactions of all your relationships. Ideal for analysts, coaches, or anyone working with multiple partnerships.'
            },
            getUnlimited: 'Get Unlimited'
        }
    },
    unlock: "Unlock",
    unlockChartSubTitle: "Gain access to the complete analysis",
    unlockChartSubTitles: {
        foundational: 'Discover the Power of Your Design for Authentic, Purposeful Living',
        advanced: 'Radically Transform Your Life by Aligning with Your Unique Body and Mind',
        companionship: 'Foster Deeper Understanding and Harmony in Your Relationship',
        transit_tool: 'Navigate Life’s Conditioning Forces with Real-Time Planetary Insights',
        work_success: 'Discover Your Profit Potential to Achieve Authentic Financial Abundance'
    },
    unlockCelebrityChartSubtitle: 'Purchase a Foundational Analysis Unlimited subscription to gain access to all teachings for all Celebrity Charts',
    unlockCelebrityChartBtn: 'Subscribe Now',
    unlockTransitToolSubtitle: '<p>A Foundational or Partnership Analysis Unlimited subscription unlocks Ra’s teachings about chart properties. A Transit Tool subscription unlocks the Ephemeris, Activation Watch, and Changes tools. All subscriptions include Rave I’Ching Line descriptions.</p>',
    unlockChartDiscount: "| {percentage}% life-time discount available on all subscriptions for a limited time",
    singleUnlockChartDiscount: "{percentage}% life-time discount available for a limited time",
    unlockChartButton: "Unlock Chart",
    comingSoonButton: 'Coming Soon',
    unlockTransitChartButton: "See Subscriptions",
    checkout: {
        single:  {
            text: '<p class="mb-0">A <strong>Single Chart Unlock</strong> gives you immediate, lifetime access to all the transformative teachings about a specific chart. Your purchase includes a discount code to upgrade to the <strong>Unlimited Subscription</strong> for free (the amount you paid for the single chart will be deducted from the yearly fee). And if you’re unlocking an analysis for someone else’s chart, or purchasing a partnership analysis, you can gift the analysis and retain access yourself!',
            empty: 'Please select an analysis from the left to continue.',
        },
        subscription: {
            text: '<p class="mb-0">All <strong>Unlimited Analysis Subscriptions</strong> give you access to all the transformative teachings about unlimited charts. All subscriptions (including the Transit Tools) include access to the Rave <strong>I’Ching—Ra’s</strong> original descriptions of each of the 384 Lines.</p>',
            empty: 'Please select a subscription from the left to continue.',
        },
        info: 'You can purchase multiple yearly subscriptions or single chart unlocks at the same time, but it’s not possible to purchase a combination of subscriptions and single unlocks at the same time. It is also not possible to purchase a yearly and monthly subscription at the same time.',
        subscribe: 'Subscribe',
        title: 'Checkout',
        addons: 'Add-Ons',
        tabs: {
            subscription: 'Subscriptions',
            unlock: 'Single Chart Unlocks'
        },
        types: {
            single: 'Single Chart Unlocks',
            subscription: 'Subscriptions'
        },
        subtotal: 'Order Sub-total',
        discounts: 'Discounts',
        total: 'Order Total',
        summary: 'Summary',
        products: 'Products',
        payMethod: 'Payment Method',
        addToOrder: 'Add to Order',
        learnMore: 'Learn More',
        selectChart:'Select a chart to unlock',
        expiredCoupon: 'This coupon is not valid!',
        autoCoupon: 'Your yearly discount has automatically been applied!',
        addMoreForCoupon: 'Add more analysis subscriptions to discount your yearly rate!',
        thanks: {
            title: `Thank You`,
            text: '<p>Your order has been confirmed. You will receive an email confirming your payment and another with more information about your purchase.</p>' +
                '<p> In the meantime, you can jump right back into your analysis. Enjoy!</p>',
            lastChart: {
                title: 'Load Last Bodygraph',
                subtitle: 'Continue Analysis',
            },
            library: {
                title: 'Open your Library',
                subtitle: 'Your Charts'
            },
            gift: 'Want to share this analysis as a gift?',
        }
    },
    intervals: {
        0: '/ year',
        1: '/ month'
    },

    products: {
        title: 'All Products',
        names: {
            foundational: 'Foundational Analysis',
            advanced: 'Advanced Analysis',
            companionship: 'Partnership Analysis',
            partnership: 'Partnership Analysis',
            transit_tool: 'Transit Tool',
            work_success: 'Personal Success Analysis',
        },
        subscriptions:{
            transit_tool: '<p>Unlock the <strong>Ephemeris</strong>, <strong>Activation Watch</strong>, and <strong>Changes</strong>&mdash;tools that allow you to explore the transits, shift through activations, and visually track planetary changes over time. Includes the Rave I&rsquo;Ching.</p>',
         },
        long: {
            foundational: '<p>Start your journey into Human Design with free teachings about your <strong>Type, Strategy, and Authority</strong>&mdash;your unique decision-making strategies. Unlock the rest for a comprehensive analysis of your Human Design:</p>\n' +
                '<ul>\n' +
                '<li><strong>Conditioning</strong>: Where you are susceptible to influence and pressure but also where your potential for wisdom lies.</li>\n' +
                '<li><strong>Life-Force</strong>: The way energy flows through your consistent, fixed and reliable qualities.</li>\n' +
                '<li><strong>Imprint</strong>: Your conscious and unconscious characteristics.</li>\n' +
                '<li><strong>Role, Purpose, and Direction</strong>: Your potential as a result from experimenting with and living your Human Design.</li>\n' +
                '</ul>\n' +
                '<p><em>In the language of Human Design, the following is covered: Type, Inner Authority, Open Centers, Definition, Defined Centers, Channels, Circuitry, Gates, Planets, Profile, Nodes, and Incarnation Cross.</em></p>',
            advanced: '<p>Once you\'ve been experimenting with your Strategy &amp; Authority for some time&mdash;and if it\'s correct for you&mdash;you can enter into advanced transformation practice to discover how your body and mind operate uniquely for you.</p>\n' +
                '<p>The Four Transformations, also called Variables, reveal how you can:&nbsp;</p>\n' +
                '<ul>\n' +
                '<li><strong>Nourish Your Body &amp; Brain</strong>: The particular Dietary Regimen that enables your body to absorb nutrients properly&mdash;enhancing the entire body&rsquo;s well-being and the brain&rsquo;s functioning.</li>\n' +
                '<li><strong>Recognize Your Path</strong>: The correct Environment harmonizes with your body&rsquo;s resonance to outer conditions and is critical for longevity, the quality of life, and for developing your Perspective.</li>\n' +
                '<li><strong>How You View Life Uniquely</strong>: Your Perspective develops your awareness of how you uniquely see life experiences. It is how you gain wisdom and provides you with signposts to notice when you get lost in distraction.</li>\n' +
                '<li><strong>How Your Mind Conceptualizes</strong>: Motivation and Awareness determines the unique way you think and conceptualize, and the expression of what you have learned through your life.</li>\n' +
                '</ul>\n' +
                '<p>For this next step in your journey, it is crucial you have highly accurate birth time (down to minutes) as advanced properties shift more rapidly than foundational properties.</p>\n' +
                '<p><em>In the language of Human Design, the following is covered: Design and Personality Internal and External Color and Tone (Primary Health System and Rave Psychology, or the Four Transformations: Determination, Environment, Perspective, and Awareness), and Variable.</em></p>',
            companionship: '<p>Bring greater awareness to your relationships, work through challenges, and appreciate unique dynamics with a partnership analysis.&nbsp;</p>\n' +
                '<p>Combine any two Human Design charts to create a connection chart, which can reveal any of the following:</p>\n' +
                '<ul>\n' +
                '<li><strong>Connection Theme</strong>: Determines how open or closed a partnership is to external stimulation, helping you understand and navigate the partnership.</li>\n' +
                '<li><strong>Electromagnetic</strong>: Attraction and repulsion, love and hate&mdash;often the spark of a partnership but requires awareness to navigate the ups and downs.&nbsp;</li>\n' +
                '<li><strong>Dominance</strong>: Where one partner is designed to learn from, and accept and surrender to, a powerful conditioning force and consistent theme of the other.&nbsp;</li>\n' +
                '<li><strong>Compromise</strong>: A particularly restrictive and challenging dynamic that, without awareness, can end up being the make-or-break point of a partnership.</li>\n' +
                '<li><strong>Companionship</strong>: The potential for friendship and shared experiences&mdash;can create stability within the partnership, but also invisibility.</li>\n' +
                '</ul>\n' +
                '<p>The analysis concludes with the <strong>Gates of Love</strong>, 10 Gates in the BodyGraph that can have a profound impact on how each partner experiences love&mdash;especially powerful if they form an electromagnetic connection.</p>',
            transit_tool: '<p><strong>Transit Tools</strong></p>\n' +
                '<p><strong>What are transits?</strong></p>\n' +
                '<p>In Human Design, a Transit refers to the movement of the planets through the 64 Gates of the Human Design Mandala Wheel. Mapping these movements shows the overall planetary configuration at any given moment, and how it impacts our individual Chart.</p>\n' +
                '<p>Each planetary body has its unique frequency theme and energy transmission which is then expressed through the specific Gate it is transiting. We each carry an imprint of the planets and their Gate energies which makes up our BodyGraph, and can use transits as a tool to observe how we are impacted at any moment by these transiting conditioning forces.</p>\n' +
                '<p>A Human Design BodyGraph can be connected with a Transit Chart, creating a combined chart which represents the energies at play. We can think of it like a personalized weather forecast that prepares us for upcoming global conditioning themes.</p>\n' +
                '<p><strong>The benefits of observing Transits:</strong></p>\n' +
                '<ul>\n' +
                '<li>Develop an awareness of the difference between who you are, and who you are not by witnessing the impact of transits on yourself and others</li>\n' +
                '<li>Learn about the planets while observing their impact as they transit specific Gates relating to your individual Design</li>\n' +
                '<li>Mentally prepare for the conditioning \'weather\' so you are less likely to deviate from your Strategy and Authority</li>\n' +
                '</ul>\n' +
                '<p>Explore the \'Human Design Weather\' with our advanced and feature-packed Transit Tool:</p>\n' +
                '<ul>\n' +
                '<li><strong>Create unlimited Transit and Transit Connection Charts</strong> (Composite Chart of Individual Chart and Transit) for any Transit date between 1900 &ndash; 2060</li>\n' +
                '<li><strong>Live Just Now &amp; Just Now Connection Chart</strong> that update automatically</li>\n' +
                '<li><strong>Interactive Ephemeris</strong> to track planetary movements between 1900 &ndash; 2060</li>\n' +
                '<li><strong>Activation Watch</strong>: a tool to explore Line shiftings of each Planet</li>\n' +
                '<li><strong>Changes</strong> overview reveals next activations, the exact moment of Gate and Line changes, and retrograde data for all planets at a glance</li>\n' +
                '</ul>\n' +
                '<p><strong><em>Note</em></strong><em>: This is a software tool, providing the means of exploring the transits, but does not yet include educational material to teach or analyze Transit themes. Integration of the I\'Ching (included with your subscription) and Foundational Analysis content supplement the Transit Tool.</em></p>',
            comparison: '<h5><strong>Single vs. Subscription Analysis</strong></h5>\n' +
                '<p>At myBodyGraph you can choose to unlock Ra Uru Hu&rsquo;s teachings in two ways:</p>\n' +
                '<ul>\n' +
                '<li><strong>Single Unlock</strong>: a one-time purchase to unlock an analysis for a single chart. The analysis is unlocked and available forever in your account.</li>\n' +
                '<li><strong>Subscription</strong>: a yearly subscription to unlock an analysis product for unlimited charts. The analysis content is unlocked for as long as the subscription is active. All subscriptions include the Rave I&rsquo;Ching&mdash;Ra&rsquo;s original descriptions of each of the 384 Lines.</li>\n' +
                '</ul>' +
                '<p><strong>Tip:</strong> After purchasing a Single Chart Unlock for the first time, you will automatically receive a coupon code to discount the equivalent amount for an Unlimited Subscription. <strong>The code is valid for 1 month.</strong></p>'
        }
    }
}
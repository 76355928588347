<template>
  <div v-if="hasPentaChannels(chart)">
    <dynamic-zone-renderer v-for="gate in data"
                           :key="gate.key"
                           :title="gate.title"
                           :components="gate.components"
                           :add-paddings="false"
                           is-toggleable
                           @titleMouseover="highlightElement({
                                  type: types.channel,
                                  id: getChannelFromKey(gate.key).id,
                                  includePlanets: true,
                                  extras: {
                                    channelGates: getChannelFromKey(gate.key).gates
                                  }
                           })"
                           @titleMouseleave="clearAllHighlights"
    />
  </div>
  <div v-else>
    <dynamic-zone-renderer
                           :components="noContent ? noContent.components : []"
                           :add-paddings="false"
    />
  </div>
</template>

<script>

import WorkSuccessMixin from "@/mixins/WorkSuccessMixin";
import DynamicZoneRenderer from "@/components/renderer/DynamicZoneRenderer.vue";
import AnalysisQueryMixin from "@/mixins/AnalysisQueryMixin";
import HighlightingMixin from "@/mixins/HighlightingMixin";
import channels from "@/helpers/rave/constants/channels";

export default {
  components: {DynamicZoneRenderer},
  mixins: [WorkSuccessMixin, AnalysisQueryMixin, HighlightingMixin],
  props: {
    chart: Object
  },

  data(){
    return {
      data: [],
      noContent: null,
    }
  },

  mounted() {
    if (this.hasPentaChannels(this.chart)) {
      this.getPentaChannels(this.chart)
    } else {
      this.getContent('noContent', '/analysis/introductions/introduction.no-penta-channel');
    }
  },
  methods: {
    // TODO: Consider moving this logic to the highlighting mixin instead.
    // Will make it easier to maintain and reuse.
    getChannelFromKey(key) {
      return channels.find(x => x.key === key)
    }
  }
}
</script>
<template>

  <div class="unlock-chart-prompt text-center" style="border-radius: 0 0 10px 20px;">
    <span class="icon-small-lock" style="font-size:30px;"></span>
    <h4 class="mt-3">

      <span v-if="isCelebrity">
        {{$t('billing.unlockTypes.celebrity')}}
      </span>
      <span v-else-if="category !== 'transit_tool'">
          {{$t('billing.unlock')}} {{$t('billing.unlockTypes.'+category)}}
      </span>
      <!-- special case for transit tool title -->
      <span v-else>
         {{$t('billing.unlockTypes.'+category)}}
      </span>


    </h4>
    <div class="mbg-text-muted px-3">

      <div v-if="category === 'transit_tool'" v-html="$t('billing.unlockTransitToolSubtitle')"></div>
      <p v-else-if="isCelebrity" class="w-75 mx-auto">
        {{$t('billing.unlockCelebrityChartSubtitle')}}
      </p>
      <p v-else>
        {{ $t('billing.unlockChartSubTitles.' + category) }}
      </p>
      {{ DISCOUNTS_ENABLED[category] ? $t('billing.unlockChartDiscount', { percentage: DISCOUNT_PERCENTAGE[category]}) : '' }}
    </div>

    <b-button variant="unlock-chart" @click="unlockChart" :disabled="!!comingSoonMode">
      <span v-if="comingSoonMode">
        {{$t('billing.comingSoonButton')}}
      </span>
      <span v-else-if="isCelebrity">
        {{$t('billing.unlockCelebrityChartBtn')}}
      </span>
      <span v-else-if="category !== 'transit_tool'">
        {{$t('billing.unlockChartButton')}}
      </span>
      <span v-else>
        {{$t('billing.unlockTransitChartButton')}}
      </span>
    </b-button>

    <div v-if="showModal" class="unlock-chart-modal">
      <div class="unlock-chart-modal-backdrop backdrop-visible" @click.self="showModal=false"></div>
      <div class="unlock-chart-modal-content-wrapper" @click.self="showModal=false">
        <div class="unlock-chart-modal-content"  @click.self="showModal=false">

          <div class="d-md-flex align-items-end">
            <div class="unlock-box single-unlock">
              <div>
                <h3>{{ $t('billing.unlockTypes.' + category)}}</h3>
                <h4>{{ $t('billing.chart.single.title') }}</h4>
              </div>

              <h3 class="price">
                <span class="text-normal font-weight-light">$</span>{{currentProducts.single.price}}
              </h3>
              <p class="mbg-text-muted font-weight-light" style="margin-top:-25px;">{{$t('billing.chart.subscription.oneTimePayment')}}</p>

              <div class="box-bullets" v-html="formatBullets($t('billing.chart.single.' + category + '.bullets'))" />
              <p class="desktop-version" style="height:100px;"></p>
              <div class="cta mt-auto">
                <b-button class="btn-unlock-chart-prompt" @click="unlockChartSingle">{{$t('billing.unlockChartButton')}}</b-button>
              </div>

            </div>
            <div class="unlock-box unlimited-unlocks">
              <div class="">
                <h3>{{$t('billing.unlockTypes.' + category)}}</h3>
                <h4 class="unlimited-unlocks-title mb-0">{{ $t('billing.chart.subscription.title') }}</h4>
              </div>

              <h3 class="price">
                <span v-if="DISCOUNTS_ENABLED[category]" class="d-block " style="text-decoration: line-through;line-height:30px;font-size:18px;">
                    <span class=" font-weight-light">$</span>{{currentProducts.subscription.price}}
                </span>
                <span class="text-normal font-weight-light">$</span>{{DISCOUNTS_ENABLED[category] ? calculateDiscount(currentProducts.subscription.price) : currentProducts.subscription.price}}
              </h3>
              <p class="mbg-text-muted font-weight-light" style="margin-top:-25px;">{{$t('billing.chart.subscription.paidYearly')}}</p>

              <div class="box-bullets unlimited-bullets  mb-md-5" style="" >
                <p v-if="DISCOUNTS_ENABLED[category]" class="text-center">
                  {{ $t('billing.singleUnlockChartDiscount', { percentage: DISCOUNT_PERCENTAGE[category]}) }}
                </p>
                <div class="text-center" v-html="formatBullets($t('billing.chart.subscription.' + category + '.bullets'))"/>
              </div>

              <div class="cta mt-auto">
                <b-button variant="primary" @click="unlockSubscription">{{$t('billing.chart.subscription.getUnlimited')}}</b-button>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>


<style lang="scss">
.box-bullets{
  text-align: left;
  font-size:13px;
  ul {
    list-style: none;
    li{
      padding-bottom:10px;
    }
    li:before {
      font-family: 'MyBodyGraph' !important;
      speak: never;
      content: "\e912";
      margin-right:15px;
    }
  }
}
.btn-unlock-chart-prompt{
  background: rgba(255, 255, 255, 0.05)!important;
  border: 1.5px solid #FFFFFF!important;
  border-radius: 5px 15px!important;
  &:hover{
    font-weight:initial!important;
    background: rgba(255, 255, 255, 0.25)!important;
  }
}
.unlock-box{
  padding: 25px 40px;
  border-radius: 20px 40px;
  .price{
    font-weight: 400!important;
    font-size: 80px;
    line-height: 98px;
  }
  .savings-btn{
    color:white!important;
    text-decoration: underline!important;
    &:hover{
      opacity:60%;
    }
  }
}
.single-unlock{
  color:white;
  position:relative;
  padding:15px;
  background: rgba(42, 40, 40, 0.9);
  box-shadow: 0 10px 25px rgba(24, 28, 36, 0.05);

  @media(max-width:767px){
    margin: 0 auto;
  }
  @media(min-width:767px){
    max-width:380px;
    padding-right:80px;
    margin-right:-60px;
  }
}
.unlimited-unlocks{
  z-index:2;
  position:relative;
  padding:15px;
  background: $bg-dark;

  @media(max-width:767px){
    margin: 20px auto 125px;
  }

  @media(min-width:767px){
    max-width:380px;
    //padding-right:80px;
    //margin-right:-60px;
  }

  .unlimited-bullets{

    @media(min-width:767px){
      padding:55px 0
    }
  }

  color:white;
  .unlimited-unlocks-title{
    background-color:$orange;
    margin: 0 auto;
    padding:7px 15px;
    width:fit-content;
    color: $bg-dark;
    margin-bottom:10px;
    border-radius: 5px 10px;
  }
}
.unlock-chart-modal{
  position:fixed;
  top:0;left:0;right:0;bottom:0;
  z-index:5;
}
.unlock-chart-modal-content-wrapper{
  height:100%;

  .unlock-chart-modal-content{
    z-index: 6;
    height:100%;
    overflow-y:auto;
    position:relative;
    padding:10px;

    @media(min-width:767px){
      display:flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.unlock-chart-prompt{
  //border-top:.5px solid;
  //box-shadow: 10px -30px 45px -30px rgba(0, 0, 0, 0.05);
 padding:20px;
}

.unlock-chart-modal{
  .unlock-chart-modal-backdrop{
    z-index:5;
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    background:rgba(0,0,0,0.7);
    transition:visibility 0.3s linear,opacity 0.3s linear;
    visibility: hidden;
    opacity:0;
    backdrop-filter: blur(5px);
    &.backdrop-visible{
      visibility: visible;
      opacity:1
    }
  }
}
</style>


<script>

import {mapState} from "vuex";
import {createSingleCartItem} from "../../helpers/checkout_helper";
import {DISCOUNT_PERCENTAGE, DISCOUNTS_ENABLED} from "../../helpers/discounts";

export default{

  props:{
    category: String, // foundational, advanced, companionship
    chartId: String, // chart id to redirect to!,
    comingSoonMode: Boolean
  },

  computed:{
    ...mapState({
      chart: state => state.charts.chart
    }),

    isCelebrity(){
      return this.chart.isCelebrity
    },
    DISCOUNT_PERCENTAGE() {
      return DISCOUNT_PERCENTAGE
    },
    DISCOUNTS_ENABLED() {
      return DISCOUNTS_ENABLED
    },
    ...mapState({
      products: state => state.checkout.products
    }),

    currentProducts(){
      let single_unlock = this.products.singles.find(x => x.key === this.category);
      let subscription = this.products.subscriptions.find(x => x.key === this.category);


      return {
        single: single_unlock,
        subscription: subscription
      }
    }
  },

  data(){
    return{
      showModal: false
    }
  },
  methods:{
    formatBullets(content) {
      return `<div class="text-center">${content.replaceAll('.', '. <hr class="m-0 opacity-0" style="height:12px;display:block;" />')}</div>`
    },
    calculateDiscount(price){
      return (price * (100 - DISCOUNT_PERCENTAGE[this.category]) / 100).toFixed(0)
    },
    unlockChartSingle(){
      this.$store.commit('checkout/setCheckoutType', this.CheckoutTypes.SINGLE);
      this.$store.commit('checkout/addSingleCartItem', createSingleCartItem({
        id: this.$store.state.charts.chart.id,
        name: this.$store.state.charts.chart.meta.name
      }, this.currentProducts.single));
      this.$router.push({name: 'checkout'});
    },
    unlockSubscription(){
      this.$store.commit('checkout/setCheckoutType', this.CheckoutTypes.SUBSCRIPTION);
      this.$store.commit('checkout/addSubscriptionCartItem', this.currentProducts.subscription);
      this.$router.push({name: 'checkout'});
    },

    unlockChart() {
      // if (this.comingSoonMode) return;
      if (this.isCelebrity || this.category === 'transit_tool') {
        this.$router.push('/checkout')
      } else {
        this.showModal = true;
      }
    }
  }
}
</script>
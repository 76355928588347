<template>
  <b-card id="version-checker" v-if="requiresUpdate" class="position-fixed shadow-lg py-2">

    <b-card-title style="font-size:14px;">New Version Available</b-card-title>

    <p style="font-size:12px;">
      A fresh update is ready with improvements and fixes. Update now to enjoy the best experience and latest features.
    </p>

    <b-button variant="primary" block size="xs" @click="update">
      Update now
    </b-button>
  </b-card>
</template>
<script>
import {getLocalStorageItem, localStorageKeys, setLocalStorageItem} from "@/utils/localStorage";

export default {

  data(){
    return {
      currentVersion: null,
      liveVersion: '1.0.0',
      requiresUpdate: false,
      interval: null,
    }
  },

  created() {
    this.currentVersion = getLocalStorageItem(localStorageKeys.VERSION);
    this.checkAndUpdateVersion();

    this.interval = setInterval(() => {
      this.checkAndUpdateVersion();
    }, 60 * 1000); // every 1 minute

  },

  beforeDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  },

  methods: {

    checkAndUpdateVersion() {

      this.axios.get('/content/config?key=version').then(res => {
        this.liveVersion = res.data?.value || '1.0.0';
        if (this.currentVersion === this.liveVersion) this.requiresUpdate = false;

        if (!this.currentVersion) this.updateVersion(this.liveVersion);
        else {
          if (this.currentVersion !== this.liveVersion) this.requiresUpdate = true;
        }
      }).catch(() => {});
    },
    updateVersion(liveVersion) {
      this.currentVersion = liveVersion;
      setLocalStorageItem(localStorageKeys.VERSION, liveVersion);
      this.requiresUpdate = false;
    },

    update() {
      this.updateVersion(this.liveVersion);
      // reload window
      window.location.href = window.location.origin + window.location.pathname + '?version_refresh=' + new Date().getTime();
    }
  }
}
</script>

<style lang="scss">
#version-checker{
  width: 95%;
  bottom:50px;
  left:15px;
  right:15px;
  @media(min-width: 640px){
    left: auto;
    right: 15px;
    max-width:350px;
  }
}
</style>
<template>
  <component :is="isCheckout ? 'div' : `router-link`"
             :class="` py-0
             ${isSearch ? 'search-origin cursor-pointer' : ''}
             ${index === 0 ? ( total === 1 ? 'only-first-elem' : 'first-elem') : ''}`"
             :to="`/charts/${chart.id}`"
             class="chart-library-card card search-card ease-in-out duration-300 w-100"
             @click="elementClicked"
  >
    <div class="card-body py-0" @mouseover="isHovering = true" @mouseleave="isHovering = false">
      <div class="d-flex chart-details w-100 flex-shrink-0 align-items-center cursor-pointer">
        <div class="mr-3 cursor-pointer" style="width:50px;padding:11px 0;"
             @click="checkBoxWrapperClicked" >
          <button v-show="(!isHovering && !isSelected) || isCheckout || isSearch"
                  class="initials-box float-left text-center cursor-pointer border-0"
                  @click="checkBoxWrapperClicked">
            {{ getInitials(chart.name, isCompanionshipChart) }}
          </button>
          <div v-show="showCheckbox" style="height:30px" class="item-selector chart-checkbox cursor-pointer d-flex align-items-center justify-content-center">
            <b-form-checkbox
                v-show="showCheckbox"
                             :id="`chart-item-${chart.id}`"
                             :checked="isSelected"
                             class=" h-auto justify-self-center cursor-pointer"
                             style="width:49px;min-height:unset;margin-left:43px;margin-top:-5px;"
                             @change="elementClicked(true)"
            />
          </div>
        </div>

        <div class="d-inline d-f cursor-pointer w-25 chart-name">
          <div class="mb-0 font-weight-bold text-sm d-flex align-items-center gap-10">
            {{ chart.name }}
            <div class="unlock-states">
              <div v-if="isRaveChart">
                <span v-if="isUnlocked('foundational')"
                      v-b-tooltip.hover
                      :title="`Foundational Analysis Unlocked`"
                      class="icon-foundational-analysis foundational mr-2"
                >
                </span>
                <span v-if="isUnlocked('advanced')"
                      v-b-tooltip.hover
                      :title="`Advanced Analysis Unlocked`"
                      class="icon-advanced-analysis foundational mr-2"
                />
                <span v-if="isUnlocked('work_success')"
                      v-b-tooltip.hover
                      :title="`Personal Success Analysis Unlocked`"
                      class="icon-v2-work-success foundational"
                />
              </div>

              <div v-if="isCompanionshipChart">
             <span v-if="isUnlocked('companionship')"
                   v-b-tooltip.hover
                   :title="`Partnership Analysis Unlocked`"
                   class="icon-companionship foundational mr-2"
             />
              </div>
            </div>
          </div>
          <span class="mbg-text-muted">{{ $t('documentTypes.' + chart.docType) }}</span>
        </div>

        <div v-if="type && isRaveChart" class="chart-attribute">
          <p class="mb-0 text-sm"> {{ $t(`hd.constants.types.${chart.type}`) }}</p>
          <span class="mbg-text-muted">{{ $t('hd.properties.type') }}</span>
        </div>
        <div v-if="authority && isRaveChart" class="chart-attribute">
          <p class="mb-0 text-sm"> {{
              +chart.authority === Authorities.NONE ?
                  $t(`hd.constants.authorities.empty.${chart.type}`)
                  :
                  $t(`hd.constants.authorities.${chart.authority}`)
            }}</p>
          <span class="mbg-text-muted">{{ $t('hd.properties.authority') }}</span>
        </div>

        <div v-if="definition && isRaveChart" class="chart-attribute">
          <p class="mb-0 text-sm"> {{ $t(`hd.constants.definitions.${chart.definition}`) }}</p>
          <span class="mbg-text-muted">{{ $t('hd.properties.definition') }}</span>
        </div>
        <div v-if="profile && isRaveChart" class="chart-attribute desktop-version">
          <p class="mb-0 text-sm">{{ $t(`hd.constants.profiles.${chart.profile}`) }}</p>
          <span class="mbg-text-muted">{{ $t('hd.properties.profile') }}</span>
        </div>

        <div v-if="isSearch" class="ml-auto search-icon">
          <svg fill="none" height="14" viewBox="0 0 8 14" width="8" xmlns="http://www.w3.org/2000/svg">
            <path :stroke="darkMode ? '#fff' : '#191c23'" d="M1 13L7 7L0.999999 1" stroke-linecap="round"
                  stroke-linejoin="round" stroke-width="1.5"/>
          </svg>
        </div>

        <div class="ml-auto d-flex gap-6 align-items-center">
          <div v-if="!isCheckout && chart.tags && chart.tags.length > 0 ">
            <b-button :id="`popover-${chart.id}`" variant="link" size="sm" @click.prevent="">
              <TagIcon width="14" height="14"/>
            </b-button>
            <!-- Popover for each item -->
            <b-popover :target="`popover-${chart.id}`" placement="left" triggers="hover focus">
              <template #title>
                <span class="font-500">{{ $t('hd.properties.tags') }}</span>
              </template>
              <div class="grid gap-5" :class="`grid-cols-${tagTexts.length > 2 ? '3' : (tagTexts.length === 1 ? '1' : '2')}`">
                <b-badge v-for="tag in tagTexts" variant="primary" size="lg" :key="tag.id" class="font-weight-normal mx-0 py-1 px-2 w-100">
                  <span>{{ tag }}</span>
                </b-badge>
              </div>

            </b-popover>
          </div>
          <b-button v-if="showEditChart" class="ml-auto btn-xs" :variant="darkMode ? 'dark' : 'outline-dark'" @click.prevent="$emit('editChart', chart)">
            <span class="icon-edit"/>
          </b-button>
          <b-button v-if="!isSearch" class="ml-auto btn-xs h-100" variant="primary" @click="$router.push(`/charts/${chart.id}`)">
            {{ $t('general.load') }}
          </b-button>
        </div>
      </div>
    </div>
  </component>
</template>

<style lang="scss">

.theme--dark {
  .chart-library-card {
    color: white;

    &:hover {
      color: white;
    }

    background: $bg-contrast-dark !important;
    box-shadow: 0 10px 25px rgba(24, 28, 36, 0.05) !important;
  }

  .search-card:hover {
    border-color: white !important;
  }
}

.chart-library-card {
  color: initial;

  &:hover {
    color: initial;
  }

  border-radius: 0 !important;
  font-size: 10px !important;
  padding: 0;
  margin-bottom: 0.05rem;

  &.first-elem {
    border-radius: 10px 20px 0 0 !important;
  }

  &:last-of-type {
    border-radius: 0 0 10px 20px !important;
  }

  &.only-first-elem {
    border-radius: 10px 20px !important;
  }

  &.search-origin {
    margin-bottom: 0.2rem;
    border-radius: 5px 10px !important;
  }
}
.item-selector .custom-control-label{
  cursor:pointer;
}

.search-card {
  .initials-box {
    padding: 0.5rem 0;
    font-size: 0.6rem;
  }

  .search-icon {
    transition: all .5s ease-in-out;
  }

  border: 1px solid transparent !important;

  &:hover {
    border-color: $primary !important;

    .search-icon {

      margin-right: .5rem;
    }
  }
}

.chart-checkbox:hover .custom-control-label::before {
  outline: 2px solid rgba(194, 83, 84, 0.45)!important; /* Add a ring when focused */
}

.initials-box {
  width: 50px;
  background: $bg-dark;
  color: #fff;
  border-radius: 5px 12px;
  padding: 1rem 0;
  margin-right: 1rem;
  font-weight: bold;
}

.chart-details {
  > div {
    flex-shrink: 0;
  }

  .chart-attribute {
    padding: 0 0.5rem;
    width: 12.5%;
    flex-shrink: 0;
    @media(max-width: 1024px) {
      display: none;
    }
  }

  .chart-name {
    font-size: 10px;
    @media(max-width: 1024px) {
      width: 60%;
    }
    width: 20%;
  }
}
</style>

<script>
import ChartUtil from "../../mixins/ChartUtil";
import Authorities from "../../helpers/rave/constants/authorities";
import TagIcon from "@/components/icons/TagIcon.vue";
import {mapState} from "vuex";

export default {
  components: {TagIcon},
  mixins: [ChartUtil],
  props: {
    isSelected: {
      type: Boolean,
      default: false
    },
    index: Number,
    total: Number,
    chart: Object,
    isSearch: {
      type: Boolean,
      default: false
    },
    isCheckout: {
      type: Boolean,
      default: false
    },
    showEditChart: {
      type: Boolean,
      default: false
    },
    profile: {
      type: Boolean,
      default: true
    },
    definition: {
      type: Boolean,
      default: true
    },
    authority: {
      type: Boolean,
      default: true
    },
    type: {
      type: Boolean,
      default: true
    },

  },
  computed: {
    ...mapState({
      tags: state => state.account.tags,
    }),

    showCheckbox(){
      return (this.isHovering || this.isSelected) && !this.isCheckout && !this.isSearch
    },
    Authorities() {
      return Authorities
    },
    isRaveChart() {
      return this.isPersonalChart(this.chart)
    },
    isCompanionshipChart() {
      return this.isConnectionChart(this.chart);
    },
    tagTexts() {
      const texts = [];
      for (const tg of this.chart.tags) {
        const tag = this.tags.find(x => x.id === tg);
        if (tag) texts.push(tag.text);
      }
      return texts;
    }
  },
  data(){
    return {
      isClicking: false,
      isHovering: false,
    }
  },
  methods: {
    isUnlocked(type) {
      return this.getUnlockStatus(this.chart)[type]
    },
    loadChart(chart) {
      if (this.isSearch) return;
      this.redirectTo('single-chart', null, {chartId: chart.id})
    },

    checkBoxWrapperClicked(e) {
      if (this.isCheckout || this.isSearch) return;
      e.preventDefault();
      this.elementClicked();
    },

    elementClicked() {
      this.$emit('chartItemClicked');
      if (this.isClicking) return;
      this.isClicking = true;
      setTimeout(() => {
        this.isClicking = false;
        this.$emit('chartSelected', {...this.chart, isSelected: !this.isSelected});
      }, 75);
    }

  }

}
</script>
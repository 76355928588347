var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.hasPentaChannels(_vm.chart))?_c('div',_vm._l((_vm.data),function(gate){return _c('dynamic-zone-renderer',{key:gate.key,attrs:{"title":gate.title,"components":gate.components,"add-paddings":false,"is-toggleable":""},on:{"titleMouseover":function($event){_vm.highlightElement({
                                type: _vm.types.channel,
                                id: _vm.getChannelFromKey(gate.key).id,
                                includePlanets: true,
                                extras: {
                                  channelGates: _vm.getChannelFromKey(gate.key).gates
                                }
                         })},"titleMouseleave":_vm.clearAllHighlights}})}),1):_c('div',[_c('dynamic-zone-renderer',{attrs:{"components":_vm.noContent ? _vm.noContent.components : [],"add-paddings":false}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="mbg-accordion casual-border-radius-small" :class="reverseBackground ? 'reversed-bg': ''">
    <div class="mbg-accordion-title cursor-pointer px-3 d-flex align-items-center"
         @click="click">
      <p class="mb-0" :class="titleClasses ?? ''">
        {{text}}
      </p>

      <span v-if="!isLocked && !isContentLocked" class="icon-arrow-down ease-in-out duration-300 ml-auto"
            :style="isToggledState ? 'transform:rotate(180deg);': ''"/>
      <span v-else class="icon-lock ml-auto" />
    </div>

    <div v-if="isToggledState && !isLocked && !isContentLocked" class="mbg-accordion-content px-3 pb-3 pt-1">
      <div v-if="content" v-html="content" />
      <slot />
    </div>
  </div>
</template>

<script>

export default {
  props: {
    defaultOpen: Boolean,
    open: Boolean,
    text: String,
    reverseBackground: Boolean,
    isLocked: Boolean,
    content: String,
    titleClasses: String,
    overrideOpen: Boolean,
  },

  computed:{
    isContentLocked(){
      return this.content && this.content === 'LOCKED'
    },
    isToggledState() {
      return this.overrideOpen ? this.open : this.isToggled;
    }
  },
  data(){
    return {
      isToggled: !!this.defaultOpen,
    }
  },
  methods: {
    click(){
      if (this.overrideOpen) {
        this.$emit('click', !this.open);
      } else {
        this.isToggled = !this.isToggled;
      }
    }
  }
}
</script>
<style lang="scss">
.theme--light{
  .mbg-accordion{
    background-color: #f9f9f9;
    &.reversed-bg{
      background-color:white!important;
    }
  }
}
.theme--dark{
  .mbg-accordion{
    background-color: $bg-dark;
    &.reversed-bg {
      background-color: $bg-contrast-dark-low-opacity!important;
    }
  }
}
.mbg-accordion-content {
  div {
    p:last-child {
      margin-bottom: 0;
    }
  }
}

.mbg-accordion-title{
  padding-top:10px;
  padding-bottom:10px;
}
</style>
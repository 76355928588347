<script>
import {Carousel, Fancybox} from '@fancyapps/ui';
import { Thumbs } from '@fancyapps/ui/dist/carousel/carousel.thumbs.esm';
import '@fancyapps/ui/dist/fancybox/fancybox.css';
import '@fancyapps/ui/dist/carousel/carousel.thumbs.css';
import '@fancyapps/ui/dist/carousel/carousel.css';

export default {
  props: {
    options: Object,
    images: Array,
  },
  mounted() {
    new Carousel(
        this.$refs.carousel,
        {
          infinite: false,
          Dots: false,
          Thumbs: {
            type: 'classic',
            Carousel: {
              slidesPerPage: 1,
              Navigation: true,
              center: true,
              fill: true,
              dragFree: true,
            },
          },
        },
        { Thumbs }
    );

    Fancybox.bind(this.$refs.carousel, '[data-fancybox="gallery"]', {
      idle: false,
      compact: false,
      dragToClose: false,

      animated: false,
      showClass: 'f-fadeSlowIn',
      hideClass: false,

      Carousel: {
        infinite: false,
      },

      Images: {
        zoom: false,
        Panzoom: {
          maxScale: 1.5,
        },
      },

      Toolbar: {
        absolute: true,
        display: {
          left: [],
          middle: [],
          right: ['close'],
        },
      },

      Thumbs: {
        type: 'classic',
        Carousel: {
          axis: 'x',

          slidesPerPage: 1,
          Navigation: true,
          center: true,
          fill: true,
          dragFree: true,

          breakpoints: {
            '(min-width: 640px)': {
              axis: 'y',
            },
          },
        },
      },
    });
  },
  unmounted() {
    Carousel.destroy();
  },
};
</script>

<template>
  <div ref="container">
    <div id="productCarousel" ref="carousel" class="f-carousel">
      <div v-for="image in images" :key="image" class="f-carousel__slide position-relative" :data-thumb-src="image" data-fancybox="gallery" :data-src="image">
        <img alt="" :data-lazy-src="image" />
        <b-button variant="dark" size="sm" class="position-absolute" style="top:5px;right:5px;">
          <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="12"
              height="12"
              fill="currentColor"
          >
            <path
                d="M10 2a8 8 0 1 0 5.29 13.71l5.3 5.3a1 1 0 0 0 1.41-1.42l-5.3-5.29A8 8 0 0 0 10 2zm0 2a6 6 0 1 1-6 6 6 6 0 0 1 6-6z"
            />
          </svg>
        </b-button>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
:root {
  --f-brand-color: #{$primary};
}

#productCarousel,
.f-thumbs.is-classic,
.fancybox__toolbar,
.fancybox__nav {
  --f-button-width: 40px;
  --f-button-height: 40px;
  --f-button-border-radius: 4px;

  --f-button-color: #0d0c22;
  --f-button-hover-color: var(--f-brand-color);

  --f-button-svg-width: 20px;
  --f-button-svg-height: 20px;
  --f-button-svg-stroke-width: 2.25;
  --f-button-svg-filter: none;

  --f-button-svg-disabled-opacity: 0.2;
}
.theme--light {
  .f-button {
    background: $bg-light;
    &:hover {
      background: white;
    }
  }
}
.theme--dark {
  .f-button {
    background: $bg-dark;
    &:hover, &:active {
      background: $bg-contrast-dark-low-opacity
    }
    svg {
      stroke: white;
    }
  }
  .fancybox__backdrop{
    background: $bg-dark;
  }
}


.f-carousel__slide {
  display: flex;
  align-items: center;
  justify-items: center;
  img {
    max-width: 100%;
    height: auto;

  }
}

.f-thumbs.is-classic {
  --f-thumb-width: 120px;
  --f-thumb-height: 80px;
  --f-thumb-gap: 8px;

  --f-thumb-opacity: 1;
  --f-thumb-selected-opacity: 1;

  --f-thumb-outline: 2px;
  --f-thumb-outline-color: var(--f-brand-color);

  --f-button-next-pos: 8px;
  --f-button-prev-pos: 8px;
}

.f-thumbs.is-classic.is-horizontal {
  padding: 0 56px;
}

.f-thumbs.is-classic.is-vertical {
  padding: 56px 0;
}

#productCarousel .f-carousel__nav {
  opacity: 0;
  transition: opacity 0.15s;
}

@media (hover: hover) {
  #productCarousel:hover .f-carousel__nav {
    opacity: 1;
  }
}

.fancybox__container {
  --fancybox-bg: #fff;
  --fancybox-color: #6a6969;

  --f-spinner-color-1: rgba(0, 0, 0, 0.1);
  --f-spinner-color-2: rgba(17, 24, 28, 0.8);
}

@media screen and (min-width: 640px) {
  .fancybox__container {
    flex-direction: row-reverse;
  }
}

.fancybox__thumbs.is-classic.is-horizontal {
  --f-thumb-width: 75px;
  --f-thumb-height: 55px;
  --f-thumb-gap: 8px;
}

.fancybox__thumbs.is-classic.is-vertical {
  --f-thumb-width: 100%;
  --f-thumb-height: 100px;

  width: 182px;

  outline: 1px solid #edeef0;
}

.fancybox__thumbs.is-classic.is-vertical .f-thumbs__track {
  padding: 0 16px;
}

.fancybox__toolbar {
  padding: 8px;
}

.fancybox__nav {
  --f-button-next-pos: 8px;
  --f-button-prev-pos: 8px;
}

.fancybox__carousel {
  min-width: 0;
  min-height: 0;
}

.fancybox__slide {
  padding: 8px;
}
</style>
<template>
  <div v-if="fileUrl" class="audio-player" :class="isPlaying ? 'playing': ''"
       @click="markAsActiveAudioPlayer">
    <p v-if="title" class="font-500 text-lg ">{{title}}</p>

    <div class="bg-gray audio-player-wrapper d-flex align-items-center casual-border-radius p-0 pr-3" :class="alignLeft ? 'mr-auto' :'mx-auto'">
      <div v-if="waveSurferLoaded">
        <b-button variant="primary" class="p-0" style="height:50px; width:60px; flex-shrink:0" @click="playVideo" :disabled="isLocked">
          <span v-if="isLocked" class="icon-lock"/>
          <span v-else>
            <svg v-if="loading" width="20" height="20" stroke="#000" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <g class="spinner_V8m1" stroke="currentColor"><circle cx="12" cy="12" r="9.5" fill="none" stroke-width="2"></circle></g>
          </svg>
            <span v-else>
            <svg v-if="!isPlaying" width="13" height="16" viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M4.19902e-09 1.1714V14.8286C-1.5767e-05 15.0311 0.044395 15.2302 0.128898 15.4063C0.2134 15.5825 0.335108 15.7298 0.482141 15.8338C0.629174 15.9378 0.796509 15.9949 0.967814 15.9997C1.13912 16.0044 1.30854 15.9566 1.45954 15.8609L12.2446 9.03229C12.4026 8.93219 12.5346 8.78332 12.6267 8.60154C12.7188 8.41975 12.7674 8.21186 12.7674 8C12.7674 7.78814 12.7188 7.58025 12.6267 7.39846C12.5346 7.21668 12.4026 7.06781 12.2446 6.96771L1.45954 0.139101C1.30854 0.0433822 1.13912 -0.00443262 0.967814 0.000323097C0.796509 0.00507881 0.629174 0.0622426 0.482141 0.166235C0.335108 0.270227 0.2134 0.417495 0.128898 0.593665C0.044395 0.769835 -1.5767e-05 0.968888 4.19902e-09 1.1714Z" fill="white"/>
            </svg>

            <svg v-else width="16" height="16" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M6.04995 2.74998C6.04995 2.44623 5.80371 2.19998 5.49995 2.19998C5.19619 2.19998 4.94995 2.44623 4.94995 2.74998V12.25C4.94995 12.5537 5.19619 12.8 5.49995 12.8C5.80371 12.8 6.04995 12.5537 6.04995 12.25V2.74998ZM10.05 2.74998C10.05 2.44623 9.80371 2.19998 9.49995 2.19998C9.19619 2.19998 8.94995 2.44623 8.94995 2.74998V12.25C8.94995 12.5537 9.19619 12.8 9.49995 12.8C9.80371 12.8 10.05 12.5537 10.05 12.25V2.74998Z"
                  fill="currentColor"
              />
            </svg>
          </span>
          </span>
        </b-button>
      </div>
      <div class="waveform-wrapping-elem"

           :class="(isLocked || loading) ? 'd-flex align-content-stretch' : ''"
      >
        <audio-player-track v-if="isLocked || loading" class="h-75 w-100 d-flex align-self-center"
                            :class="loading ? 'animate-pulse' : isLocked ? 'opacity-25' : ''"/>
        <div :id="`waveform-${_uid}`">
        </div>
      </div>

      <div v-if="waveSurferLoaded" class="text-sm ml-2 surfer-actions"
           :class="isLocked ? 'desktop-version' : ''"
           style="width:120px">
        {{currentSec}}/{{totalSec}}
        <b-button variant="link" v-b-tooltip.hover :title="$t('general.audio.backwards')" class="ml-2 my-0 p-0 w-auto" style="padding:5px!important;"
                  :disabled="isLocked"
                  @click="jump(true)">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" class="feather feather-rotate-ccw"><polyline points="1 4 1 10 7 10"></polyline><path d="M3.51 15a9 9 0 1 0 2.13-9.36L1 10"></path></svg>      </b-button>
        <b-button variant="link" v-b-tooltip.hover :title="$t('general.audio.forwards')"
                  class="mx-0 my-0" style="padding:5px!important;"
                  :disabled="isLocked"
                  @click="jump(false)">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" class="feather feather-rotate-cw"><polyline points="23 4 23 10 17 10"></polyline><path d="M20.49 15a9 9 0 1 1-2.12-9.36L23 10"></path></svg>
        </b-button>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
.waveform-wrapping-elem ::part(cursor) {
  opacity:0.05;
}
.playing .waveform-wrapping-elem ::part(cursor) {
  opacity:1!important;
}

.audio-player-wrapper{
  width:fit-content;
  max-width:100%;

  height:50px;
  overflow:hidden;
  width:100%;
}
.waveform-wrapping-elem{
  height:100%;
  max-width:100%;
  width:100%;
  overflow:hidden;
  padding-left:5px;
}
.audio-player{
  canvas{
    z-index:0;
  }
}
.surfer-actions{
  display: flex;
  align-items: center;
}
.spinner_V8m1{transform-origin:center;animation:spinner_zKoa 2s linear infinite}
.spinner_V8m1 circle{stroke-linecap:round;animation:spinner_YpZS 1.5s ease-in-out infinite}
@keyframes spinner_zKoa{100%{transform:rotate(360deg)}}
@keyframes spinner_YpZS{0%{stroke-dasharray:0 150;stroke-dashoffset:0}47.5%{stroke-dasharray:42 150;stroke-dashoffset:-16}95%,100%{stroke-dasharray:42 150;stroke-dashoffset:-59}}
</style>

<script>
import WaveSurfer from "wavesurfer.js";
import audioStorage from "../../utils/audioStorage";
import AudioPlayerTrack from "./AudioPlayerTrack.vue";
import {mapState} from "vuex";

export default{
  components: {AudioPlayerTrack},
  props:{
    id: {
      type: Number,
      default: 0
    },
    title: String,
    fileUrl: String,
    alignLeft: {
      type: Boolean,
      default: false
    }
  },

  watch: {
    fileId(){
      this.loadAudio()
    }
  },

  computed: {
    ...mapState({
      selectedAudioPlayerId: state => state.ui.selectedAudioPlayerId
    }),
    fileId(){
      if(!this.fileUrl) return null;
      return new URL(this.fileUrl).pathname;
    },
    isLocked(){
      return this.fileUrl === 'LOCKED';
    }
  },
  data(){
    return{
      loading: true,
      options:{
      },
      waveSurfer: null,
      waveSurferLoaded: false,
      currentSec: '00:00',
      totalSec: '00:00',
      saveLocalStamp: null,
      isPlaying: false,
      wakeLock: null,
      debounceTimeout: null
    }
  },
  mounted(){
    if (!document.getElementById('waveform-' + this._uid)) return;
    if (this.isLocked) {
      this.waveSurferLoaded =  true;
      this.loading = false;
      return;
    }

    if (this.fileId) {
      this.loadAudio();
      window.addEventListener("keydown", this.handleSpaceClick);
    }
  },
  methods:{
    loadAudio() {
      this.waveSurfer = new WaveSurfer({
        container: '#waveform-'+this._uid,
        fillParent: true,
        height: 50,
        plugins: [],
      });

      this.waveSurfer.load(this.fileUrl);
      this.waveSurferLoaded =  true;

      this.waveSurfer.on('ready', async () => {
        this.totalSec = this.convertSecondsToStamp(this.waveSurfer.getDuration());

        const existingTime = await audioStorage.getItem('audio-'+this.fileId);
        if(existingTime){
          this.waveSurfer.seekTo(existingTime / this.waveSurfer.getDuration());
          this.updateCurrentStamp();
        }
        this.loading = false;

      });
      this.waveSurfer.on('audioprocess', () => {
        this.updateCurrentStamp();
      });
    },


    async syncWithServer(){
      let stamp = await audioStorage.getItem('audio-'+this.fileId);
      if(stamp === 0 || !stamp) return;
      this.axios.post('/charts/audio', {audio_id: this.fileId, latest_second: stamp}).then(()=>{}).catch(()=>{});
    },
    saveCurrentStamp(){
      if(!this.waveSurfer) return;
      let stamp = this.waveSurfer.getCurrentTime();
      if(stamp === 0 || !stamp) return;
      audioStorage.setItem('audio-'+this.fileId, stamp);
      // save.
    },
    updateCurrentStamp(){
      if(!this.waveSurfer) return;
      let totalSeconds = this.waveSurfer.getCurrentTime();
      this.currentSec = this.convertSecondsToStamp(totalSeconds);
    },
    convertSecondsToStamp(seconds){
      if(seconds > 3600) return new Date(seconds * 1000).toISOString().slice(11, 19);
      else return new Date(seconds * 1000).toISOString().slice(14, 19);
    },
    playVideo(){
      if(!this.waveSurfer) return;
      this.isPlaying = !this.waveSurfer.isPlaying();
      if(this.waveSurfer.isPlaying()){
        this.saveCurrentStamp();
        clearInterval(this.saveLocalStamp);
        this.waveSurfer.pause();
        this.cleanWakeLockIfEnabled();
      } else{
        this.saveLocalStamp = setInterval(() => {
          this.saveCurrentStamp();
        }, 5000) // save after 5s.
        this.waveSurfer.play();
        this.tryToForceWakeState();
      }
    },
    jump(backwards=false){
      let secondsToJumpTo;
      const seconds = this.waveSurfer.getCurrentTime();
      const duration = this.waveSurfer.getDuration();
      if(backwards){
        if(seconds - 15 < 0) secondsToJumpTo = 0;
        else secondsToJumpTo = seconds - 15;

      }else{
        if(seconds + 15 > duration) secondsToJumpTo = duration - 0.1; // small normalizing to prevent restarting.
        else secondsToJumpTo = seconds + 15;

      }
      this.waveSurfer.seekTo(secondsToJumpTo / duration);
      this.saveCurrentStamp();
      if(!this.waveSurfer.isPlaying()) this.waveSurfer.play();
    },
    handleSpaceClick(event) {
      if (event.code !== 'Space') return;
      event.preventDefault();
      if (this.debounceTimeout) clearTimeout(this.debounceTimeout);
      if (this.selectedAudioPlayerId !== this._uid) return;
      this.debounceTimeout = setTimeout(() => {
        this.playVideo();
      }, 100);
    },
    markAsActiveAudioPlayer(){
      if (this.selectedAudioPlayerId === this._uid) return;
      this.$store.commit('ui/SET_SELECTED_AUDIO_PLAYER_ID', this._uid);
    },

    cleanWakeLockIfEnabled() {
      if (!this.wakeLock) return;
      try {
        this.wakeLock.release();
        this.wakeLock = null;
      } catch(e) {
        // do nothing.
      }

    },
    async tryToForceWakeState() {
      try {
        if (!navigator.wakeLock) return;
        if (this.wakeLock) return;
        this.wakeLock = await navigator.wakeLock.request('screen');
      } catch(e) {
        // do nothing.
      }

    }

  },
  beforeDestroy() {
    if(!this.waveSurfer) return;
    // clear interval.
    this.saveCurrentStamp();
    this.syncWithServer();
    this.cleanWakeLockIfEnabled();
    window.removeEventListener("keydown", this.handleSpaceClick);
    if(this.saveLocalStamp) clearInterval(this.saveLocalStamp);
    if(this.waveSurferLoaded && this.waveSurfer){
      try{
        this.waveSurfer.destroy();
      }catch(e){
        // do nothing
      }
    }
  }
}
</script>